// theme.js

const generateAntdTheme = () => {
  return {
    token: {
      colorPrimary: 'var(--electric-blue)',
      fontFamily: 'Nunito Sans',
      colorLink: 'var(--electric-blue)'
    },
    components: {
      Typography: {
        fontSizeHeading1: 24,
        fontSizeHeading2: 20,
        fontSizeHeading3: 16,
        fontSizeHeading4: 14,
        fontSizeHeading5: 12,
        titleMarginBottom: '0',
        fontWeightStrong: 700,
        fontSize: 12,
        colorTextDisabled: 'var(--light-sky-blue)',
        colorLink: 'var(--cerulean)',
        colorLinkActive: 'var(--cerulean)',
        colorLinkHover: 'var(--sky-blue)',
        colorTextHeading: 'var(--deep-navy-blue)'
      },
      Layout: {
        siderBg: 'var(--dark-cyan)',
        zeroTriggerWidth: 50,
        zeroTriggerHeight: 60
      },
      Form: {
        labelColor: 'var(--deep-navy-blue)',
        labelRequiredMarkColor: 'var(--postal-red)',
        labelFontSize: 12,
        fontSize: 12,
        colorError: 'var(--postal-red)'
      },
      Input: {
        colorBorder: 'var(--pale-sky)',
        hoverBorderColor: 'var(--pale-sky)',
        activeBorderColor: 'var(--pale-sky)',
        colorText: 'var(--deep-navy-blue)',
        colorTextPlaceholder: 'var(--steel-blue)',
        colorBgContainerDisabled: 'var(--grayish-blue)',
        colorTextDisabled: 'var(--steel-blue)',
        colorError: 'var(--postal-red)',
        colorErrorBorderHover: 'var(--postal-red)',
        colorErrorText: 'var(--postal-red)',
        colorErrorBg: 'var(--postal-red)',
        borderRadius: 12,
        fontSize: 12,
        inputFontSize: 12,
        inputFontSizeSM: 10,
        paddingBlock: 10,
        paddingInline: 15,
        activeShadow: 'none',
        errorActiveShadow: 'none',
        warningActiveShadow: 'none'
      },
      InputNumber: {
        fontSize: 12,
        controlHeight: 37
      },
      Select: {
        controlHeight: 41,
        colorPrimary: 'var(--pale-sky)',
        colorPrimaryHover: 'var(--pale-sky)',
        colorTextPlaceholder: 'var(--steel-blue)',
        colorText: 'var(--deep-navy-blue)',
        colorError: 'var(--postal-red)',
        colorErrorHover: 'var(--postal-red)',
        colorErrorOutline: 'var(--postal-red)',
        colorBgContainerDisabled: 'var(--grayish-blue)',
        colorTextDisabled: 'var(--steel-blue)',
        colorBorder: 'var(--pale-sky)',
        optionActiveBg: 'var(--baby-blue)',
        optionSelectedBg: 'var(--baby-blue)',
        optionSelectedColor: 'var(--deep-navy-blue)',
        optionPadding: '10px 14px',
        fontSize: 12,
        borderRadius: 12,
        controlOutlineWidth: 0,
        // boxShadowSecondary: `0 6px 10px 0 'var(----transparent-gray)', 0 3px 6px -4px 'var(----transparent-gray)', 0 9px 28px 8px 'var(----transparent-gray)'`,
        multipleItemBg: 'var(--baby-blue)',
        multipleItemBorderColor: 'var(--baby-blue)'
      },
      Button: {
        defaultBg: 'var(--dark-cyan)',
        defaultBorderColor: 'var(--dark-cyan)',
        defaultColor: 'var(--pure-white)',
        defaultActiveBg: 'var(--dark-cyan)',
        defaultActiveBorderColor: 'var(--dark-cyan)',
        defaultActiveColor: 'var(--pure-white)',
        defaultHoverBg: 'var(--primary-dark-color)',
        defaultHoverBorderColor: 'var(--primary-dark-color)',
        defaultHoverColor: 'var(--pure-white)',
        colorPrimary: 'var(--baby-blue)',
        colorPrimaryBorder: 'var(--baby-blue)',
        colorPrimaryHover: 'var(--baby-blue)',
        colorPrimaryActive: 'var(--baby-blue)',
        colorTextLightSolid: 'var(--dark-cyan)',
        colorText: 'var(--steel-blue)',
        defaultShadow: 0,
        contentFontSize: 12,
        borderRadius: 12,
        paddingInline: 6,
        paddingBlock: 7.5,
        primaryShadow: 0,
        buttonFontSize: 12,
        colorBgTextActive: 'none',
        textHoverBg: 'none',
        controlHeight: 'auto'
      },
      Table: {
        borderColor: 'var(--pure-white)',
        headerBg: 'var(--pure-white)',
        colorBorderSecondary: 'var(--pure-white)',
        colorTextHeading: 'var(--charcoal)',
        colorText: 'var(--light-slate-gray)',
        fontSize: '12px',
        rowHoverBg: 'var(--pure-white)',
        padding: 8
      },
      DatePicker: {
        colorBorder: 'var(--pale-sky)',
        colorTextPlaceholder: 'var(--steel-blue)',
        activeBorderColor: 'var(--pale-sky)',
        hoverBorderColor: 'var(--pale-sky)',
        colorPrimary: 'var(--dark-cyan)',
        cellHoverWithRangeBg: 'var(--dark-cyan)',
        cellActiveWithRangeBg: 'var(--grayish-blue)',
        warningActiveShadow: 'none',
        errorActiveShadow: 'none',
        activeShadow: 'none',
        borderRadius: 12,
        fontSize: 12,
        inputFontSize: 12,
        inputFontSizeSM: 10,
        paddingBlock: 10,
        paddingInline: 15,
        controlHeight: 45
      },
      Card: {
        padding: 20,
        paddingLG: 20,
        borderRadiusLG: 20
      },
      Modal: {
        borderRadiusLG: 24,
        borderRadiusSM: 24,
        padding: 30,
        paddingLG: 30
      },
      Notification: {
        colorSuccess: 'var(--success)',
        colorError: 'var(--error)'
      },
      Result: {
        colorSuccess: 'var(--aqua-marine)',
        colorTextHeading: 'var(--deep-navy-blue)',
        colorTextDescription: 'var(--steel-blue)'
      },
      Divider: {
        marginLG: 10,
        margin: 10
      },
      Dropdown: {
        controlItemBgActive: 'var(--grayish-blue)',
        controlItemBgActiveHover: 'var(--grayish-blue)'
      },
      Tabs: {
        inkBarColor: 'var(--accent-blue)',
        itemSelectedColor: 'var(--accent-blue)',
        itemHoverColor: 'var(--accent-blue)'
      },
      Checkbox: {
        colorPrimary: 'var(--cerulean)',
        colorPrimaryHover: 'var(--caribbean-blue)'
      },
      Upload: {
        colorPrimary: 'var(--primary-color)'
      },
      Switch: {
        colorPrimary: 'var(--prusian-blue)',
        colorPrimaryHover: 'var(--picton-blue)'
      },
      Spin: {
        colorPrimary: 'var(--primary-color)'
      },
      Collapse: {
        colorBorder: 'var(--silver)',
        contentBg: 'var(--bright-white)',
        headerBg: 'var(--bright-white)'
      },
      Steps: {
        colorPrimary: 'var(--dark-cyan)'
      }
    }
  }
}

export default generateAntdTheme
