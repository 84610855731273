import { useEffect } from 'react'
import { routesConfig } from './routes/routes'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { listen } from 'quicklink'
import SynappTour from 'components/common/SynappTour'
// import LogRocket from 'logrocket'
import { messaging } from '../notifications/firebase'
import { onMessage } from 'firebase/messaging'
import { useNotify } from '../context/NotificationProvider'
import { useDispatch, useSelector } from 'react-redux'
import FallbackLoader from 'components/common/fallback-loader'
import TawkToWidget from 'components/pages/features/tawk-to/TawkToWidget'
import { useIdleTimer } from 'react-idle-timer'
import { AuthService } from '../redux/services'
import { clearCommonDataDetails } from '../redux/reducer/commonReducer'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

// LogRocket.init('tf2hh0/test')

// This is an example script - don't forget to change it!
// LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
//   name: 'James Morrison',
//   email: 'jamesmorrison@example.com',

//   // Add your own custom user variables here, ie:
//   subscriptionType: 'pro'
// })

// import { withQuicklink } from 'quicklink/dist/react/hoc'
// import useEncryptedJWT from "hooks/useEncryptedJWT";

function App() {
  /* notfication dependencies */
  const { showNotification } = useNotify()
  /* notfication dependencies */

  /* dispatch dependencies */
  const { communityCreateToken, communityRevokeToken } = useSelector((state) => state.common)

  const { activeProfile } = useSelector((state) => state.activeProfile)

  const dispatch = useDispatch()

  /* dispatch dependencies */

  // Define the user's role as a constant, to prevent unintentional mutations.
  // const userRole = "super-admin";

  // const a = useEncryptedJWT("user");

  // Optimize useMemo to cache filtered routes based on user role.
  // const useRoutes = useMemo(() => {
  //   if (userRole?.length) {
  //     // Ensure userRole is valid and not null or undefined
  //     return filterByRole(routesConfig, userRole, withQuicklink);
  //   }
  //   return routesConfig; // Return all routes if userRole is not specified
  // }, [userRole]);

  useEffect(() => {
    // Prefetching using quicklink library
    // This will run once when the component mounts
    // Quicklink
    listen()

    if (messaging) {
      onMessage(messaging, (payload) => {
        showNotification({
          type: 'info',
          message: payload.notification.title,
          description: payload.notification.body
        })
      })
    }

    // Disbale for security reasons

    // const handleContextMenu = (event) => {
    //   event.preventDefault() // Prevent the default context menu from appearing
    // }

    // document.addEventListener('contextmenu', handleContextMenu)
    // const handleKeyDown = (event) => {
    //   if (
    //     event.keyCode === 123 || // F12
    //     (event.ctrlKey && event.shiftKey && event.keyCode === 73) // Ctrl+Shift+I
    //   ) {
    //     event.preventDefault()
    //   }
    // }

    // window.addEventListener('keydown', handleKeyDown)

    // Cleanup function to remove the event listener if the component unmounts
    return () => {
      // document.removeEventListener('contextmenu', handleContextMenu)
      // window.removeEventListener('keydown', handleKeyDown)
      // Any cleanup code, such as removing event listeners, should go here.
    }

    // eslint-disable-next-line
  }, [])

  const handleLogout = () => {
    // Logic to log out the user

    if (activeProfile?.isAuthenticated) {
      dispatch(AuthService.logout({ payload: {}, showNotification }))
      dispatch(clearCommonDataDetails())
      setTimeout(() => {
        window.location.replace(`${AppRoutingConfig.APP_URL_LOGIN}`)
      }, 5000)
    }
    // You may want to redirect or clear user session here
  }

  // Set the idle timer with a timeout of 30 minutes
  const { reset } = useIdleTimer({
    timeout: 5 * 60 * 1000, // 5 minutes
    onIdle: process.env.REACT_APP_ENV === 'develop' ? () => {} : handleLogout,
    debounce: 250
  })

  return (
    <div
      onMouseMove={reset}
      onKeyPress={reset}
      onKeyDown={reset}
      onKeyUp={reset}
      onKeyDownCapture={reset}
      onKeyUpCapture={reset}
    >
      <SynappTour />

      {/* Conditionally render RouterProvider with filtered routes */}

      {communityCreateToken?.loading || communityRevokeToken?.loading ? (
        <FallbackLoader />
      ) : (
        <RouterProvider router={createBrowserRouter(routesConfig)} />
      )}
      <TawkToWidget
        visitorEmail={activeProfile?.email}
        visitorName={
          ` ${activeProfile?.title || ''}` +
          `${activeProfile?.first_name || ''}` +
          ' ' +
          `${activeProfile?.last_name || ''}`
        }
      />
    </div>
  )
}

export default App
