import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get instructions list
export const get_instructions_list = createAsyncThunk(
  'instructions/get_instructions_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/instructions/get-all-grouped?page=${payload.page}&pageSize=${payload.pageSize}&filter=${payload.filter}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get instructions
export const get_instructions = createAsyncThunk(
  'instructions/get_instructions',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/instructions/get-all`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update instructions
export const update_instructions = createAsyncThunk(
  'instructions/update_instructions',
  async (
    { payload, showNotification, handleCloseDialog, handleGetInstructionsData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/admin/instructions/crud`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetInstructionsData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
