import { Button, Card, Col, Flex, Form, Input, Modal, Row, Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { inputFieldValidation, textareaFieldValidation } from 'utils/formUtil'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigurePoliciesService } from '../../../../../../../redux/services'
import { useNotify } from 'context/NotificationProvider'
import { useEffect } from 'react'
import TextArea from 'antd/es/input/TextArea'

const CreateFaqDialog = ({ open, onClose, handleGetFaqData, editFaqData }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* redux dependencies */
  const dispatch = useDispatch()
  const { updateFaq } = useSelector((state) => state.configurePolicies)
  /* redux dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* form dependencies */
  const initialValues = {
    section_name: undefined,
    question_answer: [{}]
  }
  const [form] = Form.useForm()
  /* form dependencies */

  /* dialog close dependencies */
  const handleCloseDialog = () => {
    onClose()
  }
  /* dialog close dependencies */

  /* on submit handler */
  const onSubmit = (values) => {
    const { section_name, question_answer } = values
    const dataToBeSent = {
      id: editFaqData?.id,
      section_name,
      question_answer
    }
    dispatch(
      ConfigurePoliciesService.update_faq({
        payload: dataToBeSent,
        showNotification,
        handleCloseDialog,
        handleGetFaqData
      })
    )
  }
  /* on submit handler */

  /* useEffect for Set Form value on edit */
  useEffect(() => {
    if (editFaqData?.isFaqEdit) {
      form.setFieldsValue({
        ...editFaqData
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFaqData])
  /* useEffect for Set Form value on edit */

  return (
    <Modal
      destroyOnClose
      centered
      footer={false}
      open={open}
      onCancel={handleCloseDialog}
      width={800}
      afterClose={() => form.setFieldsValue({ ...initialValues })}
    >
      <Spin spinning={updateFaq.loading}>
        <Form
          id="create-faq-form"
          autoComplete="off"
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={onSubmit}
          data-lpignore="true"
          data-form-type="other"
        >
          <Flex vertical gap="1rem">
            <Flex justify="space-between" style={{ marginTop: 50 }}>
              <Typography.Title
                level={2}
              >{`${!editFaqData?.isFaqEdit ? t('CommonUtils.Create') : t('CommonUtils.Update')} ${t('ConfigurePolicies.FAQs')}`}</Typography.Title>
            </Flex>
            <Flex vertical gap="1rem">
              <Card>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Form.Item
                      label={t('ConfigurePolicies.SectionName')}
                      name="section_name"
                      rules={[...inputFieldValidation(`${t('ConfigurePolicies.SectionName')}`)]}
                    >
                      <Input
                        placeholder={`${t('CommonUtils.Validations.EnterYour')} ${t('ConfigurePolicies.SectionName')}`}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Flex>

            <Flex vertical gap="1rem">
              <Card>
                <Row gutter={[24, 0]}>
                  <Col span={24}>
                    <Form.List name="question_answer">
                      {(fields, { add, remove }) => (
                        <Flex vertical gap={'0.5rem'}>
                          {fields.map(({ key, name, ...restField }) => (
                            <Card size="small" key={key}>
                              <Flex justify="end">
                                <Button
                                  type="default"
                                  shape="circle"
                                  size="small"
                                  style={{ backgroundColor: 'transparent', border: 'none' }}
                                  icon={<DeleteOutlined style={{ color: 'var(--postal-red)' }} />}
                                  onClick={() => remove(name)}
                                  disabled={fields?.length === 1}
                                />
                              </Flex>
                              <Row gutter={[24, 0]}>
                                <Col span={24}>
                                  <Form.Item
                                    {...restField}
                                    label={`${t('ConfigurePolicies.Question')} ${name + 1}`}
                                    name={[name, 'question']}
                                    rules={[
                                      ...inputFieldValidation(
                                        `${t('ConfigurePolicies.Question')} ${name + 1}`
                                      )
                                    ]}
                                  >
                                    <Input
                                      placeholder={`${t('CommonUtils.Validations.EnterYour')} ${t('ConfigurePolicies.Question')} ${name + 1}`}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    {...restField}
                                    label={`${t('ConfigurePolicies.Answer')} ${name + 1}`}
                                    name={[name, 'answer']}
                                    rules={[
                                      ...textareaFieldValidation(
                                        `${t('ConfigurePolicies.Answer')} ${name + 1}`
                                      )
                                    ]}
                                  >
                                    <TextArea
                                      rows={4}
                                      showCount
                                      placeholder={`${t('CommonUtils.Validations.EnterYour')} ${t('ConfigurePolicies.Answer')} ${name + 1}`}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          ))}
                          <Button
                            size="large"
                            type="primary"
                            className="request-management-btn"
                            onClick={() => add()}
                            style={{ width: 'fit-content' }}
                          >
                            {t('ConfigurePolicies.AddMoreFaqs')}
                          </Button>
                        </Flex>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </Card>
            </Flex>
            <Flex gap="1rem" justify="flex-end">
              <Button size="large" type="primary" onClick={handleCloseDialog}>
                {t('CommonUtils.Cancel')}
              </Button>
              <Button size="large" form="create-faq-form" htmlType="submit">
                {t('CommonUtils.Save')}
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Spin>
    </Modal>
  )
}

export default CreateFaqDialog
