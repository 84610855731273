import { createSlice } from '@reduxjs/toolkit'
import {
  get_select_specility,
  get_physician_list,
  get_physician_profile_info,
  update_patient_info,
  get_patient_list,
  get_instructions_questionnaire,
  update_case_info,
  get_amount,
  update_amount,
  get_physician_profile_info_for_drafted_cases,
  get_community_profile_info_for_draft_case
} from '../services/caseService'
import dayjs from 'dayjs'
import { capitalizeFirstLetter, createPhoneNumberObject } from 'utils/formUtil'

const initialState = {
  loading: false,
  profileData: [],
  communityData: [],
  networkData: [],
  newCase: {
    previewCase: false,
    selectedSpeciality: {},
    selectedPhysicianOrCommunity: {},
    profileSelected: {},
    communitySelected: {},
    isProfileSelected: false,
    isCommunitySelected: false,
    patientInfo: {},
    caseInfo: {}
  },
  getSpecilityList: {
    loading: false,
    specilityData: []
  },
  getExpertPhysicianList: {
    loading: false,
    expertPhysicianData: [],
    pagination: {}
  },
  getPhysicianProfileData: {
    loading: false,
    physicianProfileData: {}
  },
  // getPhysicianProfileDataForDraftedCases: {
  //   loading: false,
  //   physicianProfileData: {}
  // },
  getProfileDataForDraftedCases: {
    loading: false
  },
  updatePatientInfo: {
    loading: false
  },
  getPatientList: {
    loading: false,
    patientData: []
  },
  getInstructionsQuestionnaire: {
    loading: false,
    getInstructionsQuestionnaireData: {}
  },
  updateCaseInfo: {
    data: {},
    loading: false
  },
  getAmount: {
    loading: false,
    amountData: null
  },
  updateAmount: {
    loading: false,
    paymentData: {}
  },
  caseID: undefined,
  isPhysicianSelected: undefined,
  caseFromOutside: false
}

export const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    clearCaseData: (state) => {
      state.newCase = initialState.newCase
    },
    clearCaseID: (state) => {
      state.caseID = initialState.caseID
    },
    pinSpeciality: (state, action) => {
      // Find the element to update
      let speciality = state.getSpecilityList?.specilityData?.data?.find(
        (speciality) => speciality.value === action.payload
      )
      // If the element exists in the array
      // Update the pinStatus
      speciality.is_pinned = !speciality.is_pinned
    },
    favouritePhysicianCard: (state, action) => {
      let physicianCard = state.getExpertPhysicianList.expertPhysicianData?.find(
        (cardData) => cardData.id === action.payload
      )
      physicianCard.isFavourite = !physicianCard.isFavourite
    },
    selectSpeciality: (state, action) => {
      state.newCase.selectedSpeciality = action.payload
    },
    triggerCaseFromOutside: (state, action) => {
      state.caseFromOutside = action.payload
    },
    setExpertIdDraft: (state, action) => {
      state.newCase.profileSelected.expert_id = action.payload
    },
    setCaseId: (state, action) => {
      state.caseID = action.payload
    },
    loadMockProfileData: (state, action) => {
      state.profileData = action.payload
    },
    loadMockCommunityData: (state, action) => {
      state.communityData = action.payload
    },
    loadMockNetworkData: (state, action) => {
      state.networkData = action.payload
    },
    selectProfile: (state, action) => {
      state.newCase.profileSelected = action.payload
      state.newCase.isProfileSelected = true
      state.newCase.isCommunitySelected = false
      state.newCase.communitySelected = {}
    },
    selectCommnuinty: (state, action) => {
      state.newCase.communitySelected = action.payload
      state.newCase.isProfileSelected = false
      state.newCase.isCommunitySelected = true
      state.newCase.profileSelected = {}
    },
    setPatientData: (state, action) => {
      state.newCase.patientInfo = action.payload
    },
    setCaseData: (state, action) => {
      state.newCase.caseInfo = { ...state.newCase.caseInfo, ...action.payload }
    },
    openPreviewCase: (state) => {
      state.newCase.previewCase = true
    },
    closePreviewCase: (state) => {
      state.newCase.previewCase = false
    },
    setPhysicianProfileData: (state, action) => {
      state.getPhysicianProfileData.physicianProfileData = action.payload
    },
    clearPatientData: (state) => {
      state.getPatientList.patientData = []
    },
    setIsPhysicianProfileSelected: (state) => {
      state.isPhysicianSelected = state.newCase.isProfileSelected
    }
  },
  extraReducers: (builder) => {
    // Get Select Speciity
    builder.addCase(get_select_specility.pending, (state) => {
      state.getSpecilityList.loading = true
    })
    builder.addCase(get_select_specility.fulfilled, (state, action) => {
      state.getSpecilityList.loading = false
      state.getSpecilityList.specilityData = action.payload?.response
    })
    builder.addCase(get_select_specility.rejected, (state) => {
      state.getSpecilityList.loading = false
    })

    // Get Expert Physician
    builder.addCase(get_physician_list.pending, (state) => {
      state.getExpertPhysicianList.loading = true
    })
    builder.addCase(get_physician_list.fulfilled, (state, action) => {
      const updatedExpertPhysicianData = action.payload?.response?.data?.map((item) => {
        const {
          auth_user,
          bio,
          has_favorite,
          languages,
          physician_level,
          profession,
          specialty,
          specialty_slug
        } = item

        return {
          id: auth_user?.id,
          userNo: auth_user?.user_no,
          isLoading: false,
          isVerified: item?.auth_user?.is_profile_verified,
          profileImage: item?.physician_url,
          profileName: `${auth_user?.title} ${auth_user?.first_name} ${auth_user?.last_name}`,
          isFavourite: has_favorite ? true : false,
          onHoliday: false,
          wasActive:
            auth_user?.account_setting?.status === 'available' &&
            auth_user?.account_setting?.replies_within
              ? true
              : false,
          repliesWithIn: auth_user?.account_setting?.replies_within,
          isExpert: physician_level && physician_level !== 'None' ? true : false,
          physicianLevelTag: physician_level,
          department: profession,
          speciality: specialty,
          speciality_slug: specialty_slug,
          address:
            auth_user?.address?.city && auth_user?.address?.country
              ? `${auth_user?.address?.city}, ${auth_user?.address?.country}`
              : undefined,
          institute: auth_user?.place_of_works?.[0]?.name,
          description: bio,
          freeAssitance: auth_user?.request_config?.is_free_asst_req ? true : false,
          teleExpertiseFree: auth_user?.physician_hcp_billing_info?.is_te_free ? false : true,
          caseRate: auth_user?.physician_hcp_billing_info?.fee_for_te,
          acceptsSSN: false,
          ratings: 4.9,
          totalRatings: 64,
          languages: languages,
          teleExpertiseFee: auth_user?.physician_hcp_billing_info?.is_te_free
            ? 'Free'
            : auth_user?.physician_hcp_billing_info?.fee_for_te,
          secondOpinionWithVideo: auth_user?.physician_hcp_billing_info?.is_so_wv_free
            ? 'Free'
            : auth_user?.physician_hcp_billing_info?.fee_for_so_wv,
          secondOpinionWithOutVideo: auth_user?.physician_hcp_billing_info?.is_so_wov_free
            ? 'Free'
            : auth_user?.physician_hcp_billing_info?.fee_for_so_wov,
          identityNo: auth_user?.user_no,
          rppsNumber: auth_user?.rpps_number,
          phone: auth_user?.phone,
          email: auth_user?.email
        }
      })
      state.getExpertPhysicianList.expertPhysicianData = updatedExpertPhysicianData
      state.getExpertPhysicianList.pagination = action.payload?.response?.pagination
      state.getExpertPhysicianList.loading = false
    })
    builder.addCase(get_physician_list.rejected, (state) => {
      state.getExpertPhysicianList.loading = false
    })

    // Get Physician Profile Info
    builder.addCase(get_physician_profile_info.pending, (state) => {
      state.getPhysicianProfileData.loading = true
    })
    /* eslint-disable no-unsafe-optional-chaining */
    builder.addCase(get_physician_profile_info.fulfilled, (state, action) => {
      const {
        account_setting = {},
        address = {},
        education = [],
        email = '',
        first_name = '',
        last_name = '',
        phone = '',
        physician_hcp_billing_info = {},
        physician_hcp_info = {},
        place_of_works = [],
        profession = '',
        publications = [],
        rpps_number = '',
        specialty = '',
        title = '',
        user_no = '',
        physician_url = '',
        id,
        profile_validate_request = {}
      } = action?.payload?.response || {}

      const updatedPhysicianProfileData = {
        id,
        isLoading: false,
        isVerified: true,
        profileImage: physician_url,
        profileName: `${title} ${first_name} ${last_name}`,
        isFavourite: false,
        onHoliday: false,
        wasActive:
          account_setting?.status === 'available' && account_setting?.replies_within ? true : false,
        repliesWithIn: account_setting?.replies_within,
        isExpert:
          physician_hcp_info?.physician_level && physician_hcp_info?.physician_level !== 'None'
            ? true
            : false,
        physicianLevelTag: physician_hcp_info?.physician_level,
        department: profession,
        speciality: specialty,
        address:
          address?.city && address?.country ? `${address?.city}, ${address?.country}` : undefined,
        institute: 'XYZ Eye Center',
        description: physician_hcp_info?.bio,
        freeAssitance: account_setting?.is_acpt_free_asst ? true : false,
        teleExpertiseFree: physician_hcp_billing_info?.is_te_free ? false : true,
        caseRate: physician_hcp_billing_info?.fee_for_te,
        acceptsSSN: true,
        ratings: 4.9,
        totalRatings: 64,
        languages: physician_hcp_info?.languages,
        expertise: physician_hcp_info?.expertise,
        education: education?.map((item) => {
          return {
            diploma_name: item.diploma_name,
            type: item.type,
            university_name: item.university_name,
            year: item.graduation_year
          }
        }),
        certifications: physician_hcp_info?.board_certifications,
        publications: publications?.map((item) => {
          return {
            label: item?.title,
            url: item?.publication_link
          }
        }),
        place_of_work: place_of_works?.map((item) => {
          return {
            work_place_name: item?.name,
            type: item?.type,
            start_date: dayjs(item?.start_date).format('DD/MM/YYYY'),
            end_date: dayjs(item?.end_date).format('DD/MM/YYYY')
          }
        }),
        isCurrentlyWorkingPlaceOfWork: place_of_works?.reduce((acc, item) => {
          if (item?.is_currently_working) {
            acc = {
              address: `${item?.address?.city} ${item?.address?.country}`,
              responsibilities: item?.responsibilities
            }
          }
          return acc
        }, {}),
        subSpecialities: physician_hcp_info?.sub_specialty,
        phone: phone,
        email: email,
        teleExpertiseFee: physician_hcp_billing_info?.is_te_free
          ? 'Free'
          : physician_hcp_billing_info?.fee_for_te,
        secondOpinionWithVideo: physician_hcp_billing_info?.is_so_wv_free
          ? 'Free'
          : physician_hcp_billing_info?.fee_for_so_wv,
        secondOpinionWithOutVideo: physician_hcp_billing_info?.is_so_wov_free
          ? 'Free'
          : physician_hcp_billing_info?.fee_for_so_wov,
        identityNo: user_no,
        rppsNumber: rpps_number,
        profile_validate_request
      }
      state.getPhysicianProfileData.loading = false
      state.getPhysicianProfileData.physicianProfileData = updatedPhysicianProfileData
    })
    builder.addCase(get_physician_profile_info.rejected, (state) => {
      state.getPhysicianProfileData.loading = false
    })

    // Get Physician Profile Info for drafted cases
    builder.addCase(get_physician_profile_info_for_drafted_cases.pending, (state) => {
      state.getProfileDataForDraftedCases.loading = true
    })
    builder.addCase(get_physician_profile_info_for_drafted_cases.fulfilled, (state, action) => {
      const {
        account_setting,
        address,
        education,
        email,
        first_name,
        last_name,
        phone,
        physician_hcp_billing_info,
        physician_hcp_info,
        place_of_works,
        profession,
        publications,
        rpps_number,
        specialty,
        title,
        user_no
      } = action.payload.response
      const updatedPhysicianProfileData = {
        // id: action.payload?.response?.auth_user?.user_no,
        isLoading: false,
        isVerified: true,
        profileImage: `https://api.dicebear.com/7.x/miniavs/svg?seed=0`,
        profileName: `${title} ${first_name} ${last_name}`,
        isFavourite: false,
        onHoliday: false,
        wasActive:
          account_setting?.status === 'available' && account_setting?.replies_within ? true : false,
        repliesWithIn: account_setting?.replies_within,
        isExpert:
          physician_hcp_info?.physician_level && physician_hcp_info?.physician_level !== 'None'
            ? true
            : false,
        physicianLevelTag: physician_hcp_info?.physician_level,
        department: profession,
        speciality: specialty,
        address:
          address?.city && address?.country ? `${address?.city}, ${address?.country}` : undefined,
        institute: 'XYZ Eye Center',
        description: physician_hcp_info?.bio,
        freeAssitance: account_setting?.is_acpt_free_asst ? true : false,
        teleExpertiseFree: physician_hcp_billing_info?.is_te_free ? false : true,
        caseRate: physician_hcp_billing_info?.fee_for_te,
        acceptsSSN: true,
        ratings: 4.9,
        totalRatings: 64,
        languages: physician_hcp_info?.languages,
        expertise: physician_hcp_info?.expertise,
        education: education?.map((item) => {
          return {
            diploma_name: item.diploma_name,
            type: item.type,
            university_name: item.university_name,
            year: item.graduation_year
          }
        }),
        certifications: physician_hcp_info?.board_certifications,
        publications: publications?.map((item) => {
          return {
            label: item?.title,
            url: item?.publication_link
          }
        }),
        place_of_work: place_of_works?.map((item) => {
          return {
            work_place_name: item?.name,
            type: item?.type,
            start_date: dayjs(item?.start_date).format('DD/MM/YYYY'),
            end_date: dayjs(item?.end_date).format('DD/MM/YYYY')
          }
        }),
        isCurrentlyWorkingPlaceOfWork: place_of_works?.reduce((acc, item) => {
          if (item?.is_currently_working) {
            acc = {
              address: `${item?.address?.city} ${item?.address?.country}`,
              responsibilities: item?.responsibilities
            }
          }
          return acc
        }, {}),
        subSpecialities: physician_hcp_info?.sub_specialty,
        phone: phone,
        email: email,
        teleExpertiseFee: physician_hcp_billing_info?.is_te_free
          ? 'Free'
          : physician_hcp_billing_info?.fee_for_te,
        secondOpinionWithVideo: physician_hcp_billing_info?.is_so_wv_free
          ? 'Free'
          : physician_hcp_billing_info?.fee_for_so_wv,
        secondOpinionWithOutVideo: physician_hcp_billing_info?.is_so_wov_free
          ? 'Free'
          : physician_hcp_billing_info?.fee_for_so_wov,
        userNo: user_no,
        rppsNumber: rpps_number
      }
      state.getProfileDataForDraftedCases.loading = false
      // state.getPhysicianProfileDataForDraftedCases.physicianProfileData =
      //   updatedPhysicianProfileData
      state.newCase.profileSelected = {
        ...state.newCase.profileSelected,
        ...updatedPhysicianProfileData
      }
      state.newCase.isProfileSelected = true
      state.newCase.isCommunitySelected = false
      state.newCase.communitySelected = {}
    })
    builder.addCase(get_physician_profile_info_for_drafted_cases.rejected, (state) => {
      state.getProfileDataForDraftedCases.loading = false
    })

    // Get Community Profile Info for drafted cases
    builder.addCase(get_community_profile_info_for_draft_case.pending, (state) => {
      state.getProfileDataForDraftedCases.loading = true
    })
    builder.addCase(get_community_profile_info_for_draft_case.fulfilled, (state, action) => {
      let updatedCommunityProfileData = {}
      if (action?.payload?.response && Object.keys(action?.payload?.response)?.length > 0) {
        const {
          id,
          community_id,
          comm_request_config,
          community_title,
          community_type,
          introduction,
          comm_addresses,
          phone,
          email,
          website_url,
          language_speaken,
          comm_expertis,
          comm_specialties,
          comm_subspecialties,
          community_members,
          universities,
          publications,
          board_certifications
        } = action.payload.response
        const capitalizedCommunityTypeLetter = community_type
          .split('_')
          .map((element) => capitalizeFirstLetter(element))
          .join(' ')
        updatedCommunityProfileData = {
          id,
          communityId: community_id,
          onHoliday: false,
          wasActive: true,
          repliesWithIn: comm_request_config?.replies_within,
          communityImage: `https://api.dicebear.com/7.x/miniavs/svg?seed=${id}`,
          communityName: community_title,
          isVerified: true,
          communityType: capitalizedCommunityTypeLetter,
          description: introduction,
          address:
            comm_addresses?.[0]?.address?.city && comm_addresses?.[0]?.address?.country
              ? `${comm_addresses[0].address.city}, ${comm_addresses[0].address.country}`
              : undefined,
          phone,
          email,
          website: website_url,
          languages: language_speaken,
          expertise: comm_expertis?.map((item) => item?.expertise?.display_name),
          specialities: comm_specialties?.map((item) => item?.specialty_type?.display_name),
          communitySpeciality: comm_specialties?.map((item) => item?.specialty_type?.display_name),
          subSpecialities: comm_subspecialties?.map((item) => item?.subspecialty?.display_name),
          communityMembers: community_members?.map((item, index) => {
            return {
              member_image: `https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`,
              member_name: item?.auth_user?.member_name,
              department: item?.auth_user?.physician_hcp_info?.specialty_type?.d
            }
          }),
          universities,
          certifications: board_certifications,
          publications: publications?.map((item) => {
            return {
              url: item?.publication_link,
              label: item?.title
            }
          })
        }
      }
      state.getProfileDataForDraftedCases.loading = false
      state.newCase.communitySelected = {
        ...state.newCase.communitySelected,
        ...updatedCommunityProfileData
      }
      state.newCase.isProfileSelected = false
      state.newCase.isCommunitySelected = true
      state.newCase.profileSelected = {}
    })
    builder.addCase(get_community_profile_info_for_draft_case.rejected, (state) => {
      state.getProfileDataForDraftedCases.loading = false
    })

    // Update Patient Info
    builder.addCase(update_patient_info.pending, (state) => {
      state.updatePatientInfo.loading = true
    })
    builder.addCase(update_patient_info.fulfilled, (state, action) => {
      state.updatePatientInfo.loading = false
      state.caseID = action?.payload?.response?.case_id
    })
    builder.addCase(update_patient_info.rejected, (state) => {
      state.updatePatientInfo.loading = false
    })

    // Get Patient Data
    builder.addCase(get_patient_list.pending, (state) => {
      state.getPatientList.loading = true
    })
    builder.addCase(get_patient_list.fulfilled, (state, action) => {
      const updatedPatientData = action.payload?.response?.data?.map((item) => {
        const todayDate = dayjs()
        const birthDate = dayjs(item?.dob)
        const age = todayDate.diff(birthDate, 'year')
        const {
          id,
          first_name,
          last_name,
          last_name_by_birth,
          dob,
          birth_place,
          gender,
          patient_input_id,
          ss_number,
          ss_type,
          phone,
          email,
          address,
          nurse_identity,
          country_code
        } = item
        return {
          id,
          label: `${item?.first_name} ${item?.last_name}`,
          value: `${item?.first_name} ${item?.last_name}`,
          age: `${item?.gender}, ${age} years`,
          first_name,
          last_name,
          last_name_by_birth,
          date_of_birth: dob,
          place_of_birth: birth_place,
          gender,
          social_security_no: ss_number,
          ssn_type: ss_type,
          phone_number: createPhoneNumberObject(country_code, phone),
          email,
          home_address: address,
          identity_info: nurse_identity,
          patient_id: patient_input_id
        }
      })
      state.getPatientList.patientData = updatedPatientData
      state.getPatientList.loading = false
    })
    builder.addCase(get_patient_list.rejected, (state) => {
      state.getPatientList.loading = false
    })

    // Get Instructions Questionnaire
    builder.addCase(get_instructions_questionnaire.pending, (state) => {
      state.getInstructionsQuestionnaire.loading = true
    })
    builder.addCase(get_instructions_questionnaire.fulfilled, (state, action) => {
      const { instructions, questionnaire } = action.payload?.response?.data || []
      let updatedQuestionnaireData = questionnaire?.map((item, index) => {
        return {
          ...item,
          name: `answer_${index + 1}`
        }
      })
      state.getInstructionsQuestionnaire.loading = false
      state.getInstructionsQuestionnaire.getInstructionsQuestionnaireData = {
        instructions: instructions,
        questionnaire: updatedQuestionnaireData
      }
    })
    builder.addCase(get_instructions_questionnaire.rejected, (state) => {
      state.getInstructionsQuestionnaire.loading = false
    })

    // Update Case Info
    builder.addCase(update_case_info.pending, (state) => {
      state.updateCaseInfo.loading = true
    })
    builder.addCase(update_case_info.fulfilled, (state, action) => {
      state.updateCaseInfo.loading = false
      state.updateCaseInfo.data = action?.payload?.response
      state.caseID = action?.payload?.response?.case_id || state.caseID
    })
    builder.addCase(update_case_info.rejected, (state) => {
      state.updateCaseInfo.loading = false
    })

    // Get Amount
    builder.addCase(get_amount.pending, (state) => {
      state.getAmount.loading = true
    })
    builder.addCase(get_amount.fulfilled, (state, action) => {
      state.getAmount.loading = false
      state.getAmount.amountData = action.payload?.response?.amount
    })
    builder.addCase(get_amount.rejected, (state) => {
      state.getAmount.loading = false
    })

    // Update Amount
    builder.addCase(update_amount.pending, (state) => {
      state.updateAmount.loading = true
    })
    builder.addCase(update_amount.fulfilled, (state, action) => {
      state.updateAmount.loading = false
      state.updateAmount.paymentData = action?.payload?.response
    })
    builder.addCase(update_amount.rejected, (state) => {
      state.updateAmount.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  pinSpeciality,
  loadMockSpeciality,
  selectSpeciality,
  setExpertIdDraft,
  loadMockProfileData,
  loadMockCommunityData,
  loadMockNetworkData,
  selectProfile,
  selectCommnuinty,
  setPatientData,
  setCaseData,
  openPreviewCase,
  closePreviewCase,
  setPhysicianProfileData,
  favouritePhysicianCard,
  clearPatientData,
  clearCaseData,
  setCaseId,
  clearCaseID,
  setIsPhysicianProfileSelected,
  triggerCaseFromOutside
} = caseSlice.actions

export default caseSlice.reducer
