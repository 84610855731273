import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get my requests
export const get_my_requests = createAsyncThunk(
  'myRequests/get_my_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/cases-list`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community te my requests
export const get_community_te_my_requests = createAsyncThunk(
  'myRequests/get_community_te_my_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/te-cases-list`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community te my requests
export const get_community_so_my_requests = createAsyncThunk(
  'myRequests/get_community_so_my_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/so-cases-list`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community assignee list
export const get_community_assignee_list = createAsyncThunk(
  'myRequests/get_community_assignee_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/assignee-list`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community assignee list
export const request_assignee = createAsyncThunk(
  'myRequests/request_assignee',
  async ({ payload, showNotification, handleCloseDialog, handleGetData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/request-assign`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetData()

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// view requests
export const view_requests = createAsyncThunk(
  'myRequests/view_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/request/view`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get comments
export const get_comments = createAsyncThunk(
  'myRequests/get_comments',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/get-comments`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// change status accept, reject and decline case
export const update_request_status = createAsyncThunk(
  'myRequests/update_request_status',
  async (
    { payload, showNotification, handleCloseDialog, handleGetComments, handleGetDetails },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/te/status-update`, payload)
      if (response.status === 0) {
        handleGetComments()
        handleGetDetails()
        handleCloseDialog()
        showNotification({
          type: 'success',
          message: response?.message
        })

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add_comment
export const add_comment = createAsyncThunk(
  'myRequests/add_comment',
  async (
    {
      payload,
      showNotification,
      handleGetDetails,
      handleGetComments,
      form,
      initialValues,
      setFileList
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/te/add-comment`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleGetDetails()
        handleGetComments()
        form.setFieldsValue({ ...initialValues })
        setFileList([])

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add remove bookmark
export const add_remove_bookmark = createAsyncThunk(
  'myRequests/add_remove_bookmark',
  async (
    { payload, showNotification, handleGetDetails, handleGetComments },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/te/bookmark/add-delete`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleGetDetails()
        handleGetComments()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get my case activity
export const get_my_case_activity = createAsyncThunk(
  'myRequests/get_my_case_activity',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/get-case-activity`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* --------------------------------------- SECOND OPINION --------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */

// get my so requests
export const get_my_so_requests = createAsyncThunk(
  'myRequests/get_my_so_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/cases-list`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// view so requests
export const view_so_requests = createAsyncThunk(
  'myRequests/view_so_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/request/view`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get so comments
export const get_so_comments = createAsyncThunk(
  'myRequests/get_so_comments',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/get-comments`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// change status accept, reject and decline so case
export const update_so_request_status = createAsyncThunk(
  'myRequests/update_so_request_status',
  async (
    { payload, showNotification, handleCloseDialog, handleGetComments, handleGetDetails },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/so/status-update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleGetComments()
        handleGetDetails()
        handleCloseDialog()

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add so comment
export const add_so_comment = createAsyncThunk(
  'myRequests/add_so_comment',
  async (
    {
      payload,
      showNotification,
      handleGetDetails,
      handleGetComments,
      form,
      initialValues,
      setFileList
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/so/add-comment`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleGetDetails()
        handleGetComments()
        form.setFieldsValue({ ...initialValues })
        setFileList([])

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// add remove so bookmark
export const add_remove_so_bookmark = createAsyncThunk(
  'myRequests/add_remove_so_bookmark',
  async (
    { payload, showNotification, handleGetDetails, handleGetComments },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/so/bookmark/add-delete`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleGetDetails()
        handleGetComments()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* --------------------------------------- SUPER ADMIN --------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------- */

// get superadmin requests
export const get_superadmin_requests = createAsyncThunk(
  'myRequests/get_superadmin_requests',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/all-requests/get-list?filter=${payload?.requestType || ''}&status=${payload?.filterBy || ''}&search=${payload?.search || ''}&page=${payload?.page || ''}&pageSize=${payload?.pageSize || ''}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
