import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get physician medical reports
export const get_physician_medical_reports = createAsyncThunk(
  'medicalReport/get_physician_medical_reports',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/list/medical-report-template`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get physician medical report
export const get_physician_medical_report = createAsyncThunk(
  'medicalReport/get_physician_medical_report',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/get/medical-report-template`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// create physician medical report
export const create_physician_medical_report = createAsyncThunk(
  'medicalReport/create_physician_medical_report',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `v1/profile/create/medical-report-template`,
        payload
      )
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        navigate(AppRoutingConfig.ACCOUNT_SETTINGS)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update physician medical report
export const update_physician_medical_report = createAsyncThunk(
  'medicalReport/update_physician_medical_report',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `v1/profile/update/medical-report-template`,
        payload
      )
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        navigate(AppRoutingConfig.ACCOUNT_SETTINGS)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get synappadmin medical reports
export const get_synappadmin_medical_reports = createAsyncThunk(
  'medicalReport/get_synappadmin_medical_reports',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/list/medical-report-template`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get synappadmin medical report
export const get_synappadmin_medical_report = createAsyncThunk(
  'medicalReport/get_synappadmin_medical_report',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/get/medical-report-template`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// create synappadmin medical report
export const create_synappadmin_medical_report = createAsyncThunk(
  'medicalReport/create_synappadmin_medical_report',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/create/medical-report-template`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        navigate(AppRoutingConfig.MEDICAL_REPORT)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update synappadmin medical report
export const update_synappadmin_medical_report = createAsyncThunk(
  'medicalReport/update_synappadmin_medical_report',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/update/medical-report-template`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        navigate(AppRoutingConfig.MEDICAL_REPORT)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community medical reports
export const get_community_medical_reports = createAsyncThunk(
  'medicalReport/get_community_medical_reports',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `v1/community/list/medical-report-template`,
        payload
      )
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community medical report
export const get_community_medical_report = createAsyncThunk(
  'medicalReport/get_community_medical_report',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/get/medical-report-template`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// create community medical report
export const create_community_medical_report = createAsyncThunk(
  'medicalReport/create_community_medical_report',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `v1/community/create/medical-report-template`,
        payload
      )
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        navigate(AppRoutingConfig.COMMUNITY_REQUEST_MANAGEMENT)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update community medical report
export const update_community_medical_report = createAsyncThunk(
  'medicalReport/update_community_medical_report',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `v1/community/update/medical-report-template`,
        payload
      )
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        navigate(AppRoutingConfig.COMMUNITY_REQUEST_MANAGEMENT)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// preview te medical report
export const preview_te_medical_report = createAsyncThunk(
  'medicalReport/preview_te_medical_report',
  async (
    { payload, showNotification, handleOpenPreviewMedicalReportDialog },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/te/preview-report`, payload)
      if (response.status === 0) {
        handleOpenPreviewMedicalReportDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// preview so medical report
export const preview_so_medical_report = createAsyncThunk(
  'medicalReport/preview_so_medical_report',
  async (
    { payload, showNotification, handleOpenPreviewMedicalReportDialog },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/so/preview-report`, payload)
      if (response.status === 0) {
        handleOpenPreviewMedicalReportDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// generate te medical report
export const generate_te_medical_report = createAsyncThunk(
  'medicalReport/generate_te_medical_report',
  async ({ payload, showNotification, handleClose }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/te/generate-report`, payload)
      if (response.status === 0) {
        handleClose()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// generate te medical report
export const generate_so_medical_report = createAsyncThunk(
  'medicalReport/generate_so_medical_report',
  async ({ payload, showNotification, handleClose }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/generate-report`, payload)
      if (response.status === 0) {
        handleClose()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
