import { combineReducers } from 'redux'
import authReducer from './authReducer'
import appReducer from './appReducer'
import professionalInfoReducer from './professionalInfoReducer'
import profileInfoReducer from './profileInfoReducer'
import caseReducer from './caseReducer'
import requestManagementReducer from './requestManagementReducer'
import notificationsReducer from './notificationsReducer'
import billingInfoReducer from './billingInfoReducer'
import accountSettingsReducer from './accountSettingsReducer'
import allUsersReducer from './allUsersReducer'
import myRequestsReducer from './myRequestsReducer'
import myLibraryReducer from './myLibraryReducer'
import rolesAndPermissionReducer from './rolesAndPermissionReducer'
import directoryReducer from './directoryReducer'
import questionLibraryReducer from './questionLibraryReducer'
import myPatientsReducer from './myPatientsReducer'
import instructionsReducer from './instructionsReducer'
import medicalSettingsReducer from './medicalSettingsReducer'
import configurePoliciesReducer from './configurePoliciesReducer'
import soCaseReducer from './soReducer'
import communityReducer from './communityReducer'
import commonReducer from './commonReducer'
import physiciansReducer from './physiciansReducer'
import activeProfileReducer from './activeProfileReducer'
import dashboardReducer from './dashboardReducer'
import homeReducer from './homeReducer'
import patientsReducer from './patientsReducer'
import hcpReducer from './hcpReducer'
import myCommunityReducer from './myCommunityReducer'
import videoCallReducer from './videoCallReducer'
import medicalReportReducer from './medicalReportReducer'
import broadCastReducer from './broadCastReducer'
import notificationsWidgetReducer from './notificationsWidgetReducer'
import financeReducer from './financeReducer'

const rootReducer = () => {
  return combineReducers({
    auth: authReducer,
    app: appReducer,
    professionalInfo: professionalInfoReducer,
    profileInfo: profileInfoReducer,
    case: caseReducer,
    reqeustManagement: requestManagementReducer,
    notifications: notificationsReducer,
    billingInfo: billingInfoReducer,
    accountSettings: accountSettingsReducer,
    allUsers: allUsersReducer,
    myRequests: myRequestsReducer,
    myLibrary: myLibraryReducer,
    rolesAndPermission: rolesAndPermissionReducer,
    directory: directoryReducer,
    questionLibrary: questionLibraryReducer,
    myPatients: myPatientsReducer,
    instructions: instructionsReducer,
    medicalSettings: medicalSettingsReducer,
    configurePolicies: configurePoliciesReducer,
    soCase: soCaseReducer,
    community: communityReducer,
    common: commonReducer,
    physicians: physiciansReducer,
    activeProfile: activeProfileReducer,
    dashboard: dashboardReducer,
    home: homeReducer,
    patients: patientsReducer,
    hcp: hcpReducer,
    myCommunity: myCommunityReducer,
    videoCall: videoCallReducer,
    medicalReport: medicalReportReducer,
    broadCast: broadCastReducer,
    notificationsWidget: notificationsWidgetReducer,
    finance: financeReducer
  })
}

export default rootReducer
